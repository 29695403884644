import React, {FC, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router";
import {
    Box,
    Checkbox,
    CloseButton,
    Flex, HStack,
    Radio,
    RadioGroup,
    Stack, Tag, TagCloseButton, TagLabel,
    Text,
    useBreakpointValue,
    useToast
} from "@chakra-ui/react";
import {useAppSelector} from "../../redux/Store";
import {ListProductItemConfig, ListProductItemRow} from "../../components/List/ListProductItem";
import {Loader} from "../../components/Loader/Loader";
import {OrderController} from "../../controllers/OrderController";
import {Order, OrderTypes, ReqOrderConfig} from "../../shared/entities/Order/Order";
import {URLPaths} from "../../config/application/URLPaths";
import {Paginator} from "../../components/Paignator/Paginator";
import {ModalService} from "../../service/Modal/ModalService";
import {CheckoutRequest, ProductRequest} from "../../shared/entities/Client/CheckoutRequest";
import {OrderProductController} from "../../controllers/OrderProductController";
import {DocumentTypes, FieldType} from "../../common/constants";
import {FilterInterceptor} from "../../shared/utilities/Filters/FilterInterceptor";
import {Breadcrumbs} from "../../components/Breadcrumbs/Breadcrumbs";
import {setBreadcrumbsLinks} from "../../redux/Breadcrumbs/BreadcrumbsReducer";
import {BreadcrumbsData} from "../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {AngleDoubleRight} from "../../components/Inputs/Icons/Icons";
import ShowOrderModal from "../Modal/ShowOrderModal";
import {format, parse} from 'date-fns';
import {
    AiOutlineFileExcel,
    FiBriefcase,
    FiCopy,
    FiDownload,
    FiEye,
    FiPrinter,
    FiSend, FiTag,
    FiTrash
} from "react-icons/all";
import IconWithTooltip from "../../components/Icon/IconWithTooltip";
import PDFViewer from "../Pdf/PDFViewer";
import {ClientForm} from "../Forms/Clients/ClientForm";
import ColumnHandler from "../../components/Buttons/ColumnHandler";
import {DownloadButton} from "../../components/Buttons/DownloadButton";
import {DownloaderInstance} from "../../service/Downloader/Downloder";
import {AiOutlineClose} from "react-icons/ai";
import LoadStatementVendorPopover from "../Modal/LoadStatementVendorPopover";
import AllowedAccess, {hasPermission} from "../../features/Permission/AllowedAccess";
import {
    clientViewPermission,
    dealershipExcludeSelectPermission, orderAdminNameShowPermission,
    orderAmountShowPermission,
    orderDealershipShowPermission,
    orderEditPermission,
    orderIdShowPermission, orderRemovePermission,
    orderStatusShowPermission,
    orderViewPartnerPermission,
} from "../permissions";

export const OrderHistory: FC<{ links: Array<BreadcrumbsData>, account?: boolean }> = ({links, account = false}) => {
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const toast = useToast()
        const {id} = useParams();
        const [isLoading, setIsLoading] = useState(false)
        const [isPrint, setIsPrint] = useState(false)
        const [isExcluded, setIsExcluded] = useState('1')
        const [isDocumentType, setIsDocumentType] = useState<DocumentTypes>(DocumentTypes.Invoice)
        const [isPdf, setPdf] = useState(0)
        const [idState, setIdState] = useState<any>(null);
        const [groupOrder, setGroupOrder] = useState<any>(null);
        const [refreshOrderHistory, setRefreshOrderHistory] = useState(false);
        const orderController = new OrderController(dispatch)
        const [currentPaginatorPosition, setCurrentPaginatorPosition] = useState(0)
        const [previewPaginatorPosition, setPreviewPaginatorPosition] = useState(0)
        const modalService = new ModalService(dispatch)
        const orderProductController = new OrderProductController(dispatch);
        const [filterConfig, setFilterConfig] = useState<any>()
        const orderClientList = useAppSelector(store => store?.OrderReducer?.orderClientList)
        const dealership = useAppSelector(state => state.AuthReducer.dealership);
        const user = useAppSelector(store => store.AuthReducer.user);
        const location = useLocation();
        const containsUser = location.pathname.includes("user");
        let isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false, xl: false, "2xl": false})
        const [selectedItems, setSelectedItems] = useState<any[]>([]);
        let countSelected = (isExcluded === '2') ? orderClientList?.count - selectedItems.length : selectedItems.length
        const [filterInterceptor] = useState(new FilterInterceptor([]));
        const parseDateValue = (val: string) => {
            const matches = val.match(/\d{2}-\d{2}-\d{4}/g);
            if (matches && matches.length === 2) {
                // Парсим и форматируем каждую дату
                const fromDate = format(parse(matches[0], 'MM-dd-yyyy', new Date()), 'yyyy-MM-dd');
                const toDate = format(parse(matches[1], 'MM-dd-yyyy', new Date()), 'yyyy-MM-dd');
                return {from: fromDate, to: toDate};
            }
        }
        const buildFilters = (order?: Order) => {
            const filterConfig = Object.fromEntries(filterInterceptor.getFilterMap());

            const reqOrderConfig: ReqOrderConfig = {
                params: {
                    page: (currentPaginatorPosition === previewPaginatorPosition && (currentPaginatorPosition === 0 || filterInterceptor.getFilterMap().size !== 0)) ? 0 : (currentPaginatorPosition > 0) ? currentPaginatorPosition - 1 : 0,
                    size: 20,
                },
                data: {}
            }
            if (hasPermission(clientViewPermission)) {
                reqOrderConfig.data[containsUser ? "userId" : "dealerIds"] = (id ? containsUser ? parseInt(id) : [parseInt(id)] : undefined)
            } else {
                reqOrderConfig.data["dealerIds"] = hasPermission(dealershipExcludeSelectPermission)
                    ? (id ? [id] : user?.dealerships.filter(d => d.id && d.id !== 0).map(v => v.id as number))
                    : dealership?.id ? [dealership.id] : undefined;

            }
            if (filterConfig['DEALERSHIP']?.value !== "" && filterConfig['DEALERSHIP']?.value !== undefined) {
                reqOrderConfig.data["dealerName"] = filterConfig['DEALERSHIP']?.value
            }
            if (filterConfig['USER']?.value !== "" && filterConfig['USER']?.value !== undefined) {
                reqOrderConfig.data["userName"] = filterConfig['USER']?.value
            }
            if (filterConfig['DATE']?.value !== "" && filterConfig['DATE']?.value !== undefined) {
                const data = parseDateValue(filterConfig['DATE'].value) as { from: string; to: string };
                reqOrderConfig.data["from"] = data.from
                reqOrderConfig.data["to"] = data.to
            }
            if (filterConfig['AMOUNT']?.value !== "" && filterConfig['AMOUNT']?.value !== undefined) {
                reqOrderConfig.data["amount"] = filterConfig['AMOUNT'].value
            }
            if (filterConfig['STATUS']?.value !== "" && filterConfig['STATUS']?.value !== undefined) {
                reqOrderConfig.data["statusesId"] = Number(filterConfig['STATUS']?.value)
            }
            if (filterConfig['ORDER']?.value !== "" && filterConfig['ORDER']?.value !== undefined) {
                reqOrderConfig.data["invoiceId"] = filterConfig['ORDER'].value
            }
            if (isExcluded) {
                reqOrderConfig.data["isExcluded"] = order ? false : (isExcluded === '2')
            }
            let items = order ? [order] : selectedItems;
            if (items) {
                reqOrderConfig.data["entityIds"] = items.map(item => item.id)
            }
            return reqOrderConfig;
        };

        useEffect(() => {
            setBreadcrumbsLinks(dispatch, links);
            if (dealership) {
                if (user?.id === undefined || user === null) return;
                setPreviewPaginatorPosition(currentPaginatorPosition);
                const reqConfig = buildFilters()
                reqConfig.data["entityIds"] = [];
                orderController.getOrderList(reqConfig).then(res => {
                    if (!containsUser && id && res.Some && res.Some.orders && res.Some.orders.length > 0) {
                        setBreadcrumbsLinks(dispatch, [...links,
                            {
                                link: `id}`,
                                text: `${res?.Some?.orders?.[0]?.dealership?.name || ""}`
                            }]);
                    }
                })
            }
            if (containsUser) {
                setBreadcrumbsLinks(dispatch, [...[URLPaths.HOME, URLPaths.ORDER_HISTORY],
                    {
                        link: `${id}`,
                        text: `${id || ""}`
                    }]);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [filterConfig, user, dealership, currentPaginatorPosition, id, refreshOrderHistory])

        const handlePageChange = (newPage: number) => {
            setPreviewPaginatorPosition(currentPaginatorPosition)
            setCurrentPaginatorPosition(newPage)
        }


        interface ColumnConfig {
            id: number;
            header: string;
            width?: string;
            isLink?: boolean;
            style?: object;
            type: FieldType;
            options?: any;
        }

        const handleReorderInvoice = async (order: Order) => {
            const ProductRequestList: Array<ProductRequest> = [];
            await orderProductController.getOrderProducts(Number(order.id)).then((res) => {

                if (res.Some && Array.isArray(res.Some)) {
                    for (const elem of res.Some) {
                        const productRequest: ProductRequest = {
                            id: elem.product.id,
                            quantity: elem.quantity,
                            price: elem.price,
                            name: elem.product.name,
                            discount: elem.discount,
                            shippingCost: elem.shippingCost,
                            taxCost: elem.taxCost,
                            finalPrice: elem.finalPrice,
                            amount: Number((elem.finalPrice * elem.quantity).toFixed(2))
                        };
                        ProductRequestList.push(productRequest);
                    }
                }
            });
            if (order.user?.id !== null) {
                const checkoutRequest: CheckoutRequest = {
                    dealership: order?.dealership?.id.toString() || "0",
                    // userId: order.userId,
                    userId: Number(user?.id),
                    // poNumber: order.poNumber || "",
                    poNumber: "",
                    // namePo: order.namePo || "",
                    namePo: "",
                    subTotal: 0,
                    tax: order.tax,
                    shippingCost: order.shippingCost,
                    totalAmount: order.totalAmount,
                    reorder: true,
                    productRequest: ProductRequestList
                };
                orderController.orderCheckout(checkoutRequest).then((res) => {
                    setIsLoading(false);
                    toast({
                        position: "top",
                        title: res.None ? 'Failed reorder: ' + order.id : 'Reorder success id: ' + res.Some.id,
                        status: res.None ? 'error' : 'success',
                        duration: 3000,
                        isClosable: true,
                    })
                    setRefreshOrderHistory(prevIndex => !prevIndex);
                });
            }
        }

        const [isDownloading, setIsDownloading] = useState(false);
        const downloadInvoice = (order?: Order) => {
            const reqConfig = buildFilters(order);
            if (!order) {
                setIsDownloading(true);
            }
            orderController.invoicePdf(reqConfig).then(res => {
                if (!res.None) {
                    const blob = new Blob([res.Some], {type: 'application/pdf'});
                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(blob);
                    // downloadLink.download = `invoice-#${reqConfig.data["entityIds"].length > 1 ? 'combinated' : reqConfig.data["entityIds"][0].id}.pdf`;
                    downloadLink.download = `invoice-#${order ? order?.id : 'combinated'}.pdf`;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                }
                setIsDownloading(false);
                setSelectedItems([]);
            });
        }
        const [isConfirmPayment, setIsConfirmPayment] = useState(false);
        const confirmPayment = (order?: Order, currentState?: OrderTypes) => {
            const reqConfig = buildFilters(order);
            reqConfig.params["state"] = currentState === OrderTypes.IN ? OrderTypes.PENDING : OrderTypes.PAID
            modalService.setModalData({
                onSubmit: () => {
                    modalService.deleteModalData();
                    if (!order) {
                        setIsConfirmPayment(true);
                    }
                    orderController.orderConfirmState(reqConfig).then(() => {
                        setSelectedItems([]);
                        setRefreshOrderHistory(prevIndex => !prevIndex);
                        setIsConfirmPayment(false);
                    });
                },
                isOpen: true,
                onOpen: () => {
                },
                onClose: () => {
                    modalService.deleteModalData();
                },
                modalTextData: `Are you sure that you want to change status ${order ? order.id : countSelected} invoice ?`,
                context: "action"
            });
        };
        const downloadFunc = async (vendor: boolean, userId?: number) => {
            const reqConfig = buildFilters(); // Используем функцию для сборки фильтров
            if (reqConfig && user) {
                reqConfig.data["vendor"] = vendor
                reqConfig.data["vendorId"] = userId
                return await DownloaderInstance.getOrderListXls(reqConfig);
            }
        }
        const handlePrintPressed = (type: DocumentTypes, order?: Order) => {
            if (type === DocumentTypes.Invoice) {
                orderController.printPressed(buildFilters(order)).then(() => {
                    setRefreshOrderHistory(prevIndex => !prevIndex);
                })
            } else {
                setRefreshOrderHistory(prevIndex => !prevIndex);
            }
            setGroupOrder(order)
            setIsDocumentType(type)
            setPdf(order ? order.id : 1)
            setIsPrint(true)
        };

        const [isSendInvoice, setIsSendInvoice] = useState(false);
        const handleSendInvoice = (order?: Order) => {
            if (order && order.printPressed === 0) {
                toast({
                    position: "top",
                    title: 'Error sending invoice. The invoice must be printed at least once.',
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                })
                return;
            }
            modalService.setModalData({
                onSubmit: () => {
                    modalService.deleteModalData();
                    if (!order) {
                        setIsSendInvoice(true);
                    }

                    orderController.sendInvoice(buildFilters(order)).then((res) => {
                        toast({
                            position: "top",
                            title: res.None ? 'Failed to send' : order
                                ? `Invoice #${order?.id} has been sent successfully`
                                : `${countSelected} selected invoices have been sent successfully`,
                            status: res.None ? 'error' : 'success',
                            duration: 4000,
                            isClosable: true,
                        })
                        setIsSendInvoice(false);
                        setSelectedItems([]);
                        setRefreshOrderHistory(prevIndex => !prevIndex);
                    })
                },
                isOpen: true,
                onOpen: () => {
                },
                onClose: () => {
                    modalService.deleteModalData();
                },
                modalTextData: <Text whiteSpace="pre-wrap" wordBreak="break-word">
                    {order
                        ? `Are you sure that you want to send this invoice to ${order?.dealership?.email}?`
                        : `Are you sure that you want to send ${countSelected} selected invoices?`}
                </Text>,
                context: "action"
            });
        };

        const showInvoice = (order?: Order) => {
            setIsDocumentType(DocumentTypes.Invoice)
            setIsPrint(false)
            setGroupOrder(order)
            setPdf(order ? order.id : 1)
        }

        const columnsAll: Array<any> = isMobile ? [
                {
                    header: <Box height={"100%"}>
                        <ColumnHandler col={{id: 0, header: "DATE", type: FieldType.DateRange}}
                                       filterInterceptor={filterInterceptor} setFilterConfig={setFilterConfig}/>
                        <AllowedAccess permissionKey={orderDealershipShowPermission}>
                            <ColumnHandler
                                col={{id: 1, header: "DEALERSHIP", type: FieldType.Input}}
                                filterInterceptor={filterInterceptor} setFilterConfig={setFilterConfig}/>
                        </AllowedAccess>
                        <AllowedAccess permissionKey={orderStatusShowPermission}>
                            <ColumnHandler col={{
                                id: 2, header: "STATUS", isLink: false, width: "15%", type: FieldType.Select,
                                options: [{id: -1, name: ""}, {id: 0, name: "In"}, {id: 1, name: "Pending"}, {
                                    id: 2,
                                    name: "Paid"
                                }]
                            }} filterInterceptor={filterInterceptor} setFilterConfig={setFilterConfig}/>
                        </AllowedAccess>
                    </Box>,
                    width: "30%",
                    isLink: false,
                    style: {justifyContent: "flex-start"}
                }, {
                    header:
                        <Flex justifyContent={"center"} height="100%" display="flex" width={"100%"}>
                            <Box display="flex" flexDirection="column" justifyContent="space-between">
                                <ColumnHandler
                                    col={{id: 3, header: "ORDER", isLink: false, width: "15%", type: FieldType.Input}}
                                    filterInterceptor={filterInterceptor}
                                    setFilterConfig={setFilterConfig}
                                />
                                <AllowedAccess permissionKey={orderAmountShowPermission}>
                                    <ColumnHandler
                                        col={{id: 4, header: "AMOUNT", isLink: false, width: "15%", type: FieldType.Input}}
                                        filterInterceptor={filterInterceptor}
                                        setFilterConfig={setFilterConfig}
                                    />
                                </AllowedAccess>
                            </Box>
                        </Flex>,
                    width: "40%",
                    isLink: false,
                    style: {flexBasis: "50%", color: "grey.400",}
                }, {
                    header:
                        <Box
                            height="100%"
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            alignItems="flex-end"
                            width="100%"
                        >
                            <ColumnHandler
                                col={{id: 5, header: "USER", isLink: false, width: "15%", type: FieldType.Input}}
                                filterInterceptor={filterInterceptor}
                                setFilterConfig={setFilterConfig}
                            />
                            <Box w={"60px"}>{'INVOICE'}</Box>
                        </Box>
                    ,
                    width: "30%",
                    isLink: false,
                    style: {justifyContent: "flex-end"}
                }
            ] :
            [
                {
                    id: 0,
                    header: "DATE",
                    isLink: false,
                    width: "15%",
                    type: FieldType.DateRange
                },
                {
                    id: 1,
                    header: "ORDER",
                    isLink: false,
                    width: "10%",
                    type: FieldType.Input
                },
                {
                    id: 2,
                    header: "USER",
                    isLink: false,
                    width: hasPermission(orderDealershipShowPermission) ? "15%" : "60%",
                    type: FieldType.Input
                },
                hasPermission(orderDealershipShowPermission) && {
                    id: 3,
                    header: "DEALERSHIP",
                    isLink: false,
                    width: "25%",
                    type: FieldType.Input
                },
                hasPermission(orderStatusShowPermission) && {
                    id: hasPermission(orderEditPermission) ? 4 : 3,
                    header: "STATUS",
                    isLink: false,
                    width: "8%",
                    type: FieldType.Select,
                    options: [{id: -1, name: ""},
                        {id: 0, name: getOrderState(0, !hasPermission(orderEditPermission)).name},
                        {id: 1, name: getOrderState(1, !hasPermission(orderEditPermission)).name},
                        {id: 2, name: getOrderState(2, !hasPermission(orderEditPermission)).name}
                    ]
                },
                hasPermission(orderAmountShowPermission) && {
                    id: hasPermission(orderEditPermission) ? 5 : 4,
                    header: "AMOUNT",
                    isLink: false,
                    width: "10%",
                    type: FieldType.Input
                },
                {
                    id: 6,
                    header: "INVOICE",
                    isLink: false,
                    width: "auto",
                    type: FieldType.Input
                },
            ].filter(Boolean) as Array<ColumnConfig>;
        const selectedColumns = columnsAll;

        const ColumnNamesMapping: Array<ListProductItemConfig> = selectedColumns.map(
            (col: ColumnConfig, index: number) => {

                return {
                    content: (col.header === "INVOICE" || isMobile) ? col.header : (
                        <ColumnHandler
                            col={col}
                            filterInterceptor={filterInterceptor}
                            setFilterConfig={setFilterConfig}
                        />
                    ),
                    width: selectedColumns[index].width || "auto",
                    isLink: col.isLink || false,
                    style: col.style || {},
                };
            }
        );

        const confirmRemove = (id: number) => {
            modalService.setModalData({
                onSubmit: () => {
                    modalService.deleteModalData();
                    orderController.removeOrder(id).then((res) => {
                        toast({
                            position: "top",
                            title: res.None ? 'Failed removed id: ' + id : 'Order removed id: ' + id,
                            status: res.None ? 'error' : 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                        setRefreshOrderHistory(prevIndex => !prevIndex);
                    })
                },
                isOpen: true,
                onOpen: () => {
                },
                onClose: () => {
                    modalService.deleteModalData();
                },
                modalTextData: "Do you sure remove order",
                context: "action"
            });
        };

        function getOrderState(paymentStatus: OrderTypes, userStatus?: boolean): any {
            switch (paymentStatus) {
                case OrderTypes.IN:
                    return {name: userStatus ? "Received" : "In", color: "lightpink"}
                case OrderTypes.PENDING:
                    return {name: "Unpaid", color: "lightgoldenrodyellow"}
                case OrderTypes.PAID:
                    return {name: "Paid", color: "unset"}
                default:
                    return {name: "Undef", color: "unset"}
            }
        }

        const parseDateString = (dateString: any) => {
            const parsedDate = parse(dateString, 'MM.dd.yyyy HH:mm', new Date());
            if (!parsedDate) {
                throw new RangeError('Invalid time value');
            }
            return parsedDate;
        };


        const handleOnclickNew = (elem: any, remove: Boolean) => {
            setSelectedItems(prevSelectedItems => {
                const isSelected = prevSelectedItems.some(item => item.id === elem.id);
                if (isSelected) {
                    return remove ? prevSelectedItems.filter(item => item.id !== elem.id) : prevSelectedItems;
                } else {
                    return [...prevSelectedItems, elem];
                }
            });
        };

        const ListAll = orderClientList?.orders
            ? orderClientList.orders.map((elem: Order, idx: number) => {
                let noShowPrepareIdOrder = (elem.paymentStatus === OrderTypes.IN && !(hasPermission(orderEditPermission) || hasPermission(orderIdShowPermission)))

                const ItemConfig: Array<ListProductItemConfig> = isMobile ? [
                        {
                            content:
                                <Flex width={"100%"} justifyContent={"space-between"}>
                                    <Box width={"65%"}>
                                        <Flex justifyContent={"space-between"}>
                                            <Flex>
                                                <Checkbox font={"14px"} px={2}
                                                          icon={isExcluded === '2' ? <AiOutlineClose/> : undefined}
                                                          colorScheme={isExcluded === '2' ? 'red' : undefined}
                                                          isChecked={selectedItems.some(item => item.id === elem.id)}
                                                          onChange={() => {
                                                              handleOnclickNew(elem, selectedItems.some(item => item.id === elem.id))
                                                          }}
                                                />
                                                <Text>{format(parseDateString(elem?.createdAt), 'MM.dd.yyyy')}</Text>
                                            </Flex>
                                            <Box w={"40%"}>
                                                {noShowPrepareIdOrder ? <Text textDecoration={"underline"} cursor={"pointer"}
                                                                              _hover={{
                                                                                  color: "gray.800",
                                                                                  textDecoration: "unset"
                                                                              }}
                                                                              onClick={() => navigate(`${URLPaths.ORDER_VIEW.link}${elem?.id}`)}>{'order received'}</Text> :
                                                    <ShowOrderModal order={elem}/>
                                                }
                                            </Box>
                                        </Flex>
                                        {(hasPermission(orderEditPermission) || hasPermission(dealershipExcludeSelectPermission)) ?
                                            <>
                                                <Text>
                                                    {`${elem?.dealership?.name}${elem?.dealership?.localStatus ? ' ( L )' : ''}`}
                                                </Text>
                                                <Flex justifyContent={"space-between"}>
                                                    <Flex>
                                                        <Text>
                                                            {getOrderState(elem.paymentStatus).name}
                                                        </Text>
                                                        {(hasPermission(orderEditPermission) && (elem?.paymentStatus === OrderTypes.PENDING ||
                                                                (elem?.paymentStatus === OrderTypes.IN && ((elem.sendCount > 0 && elem.printPressed > 0) || hasPermission(orderRemovePermission))))
                                                        ) && (
                                                            <IconWithTooltip icon={AngleDoubleRight}
                                                                             tooltipMessage={'Next stage ' + (elem.paymentStatus === OrderTypes.IN ? getOrderState(OrderTypes.PENDING).name : getOrderState(OrderTypes.PAID).name)}
                                                                             onClick={() => confirmPayment(elem, elem?.paymentStatus)}/>
                                                        )}
                                                    </Flex>
                                                    <Box w={"40%"}>
                                                        {hasPermission(orderAmountShowPermission) && `$${elem?.totalAmount}`}
                                                    </Box>
                                                </Flex>
                                            </>
                                            :
                                            <Flex pl={1}>
                                                <Text>
                                                    {getOrderState(elem.paymentStatus, true).name}
                                                </Text>
                                            </Flex>
                                        }
                                    </Box>
                                    <Box width={"35%"} display="flex" flexDirection="column" justifyContent="space-between">
                                        <Box ml={"auto"}>
                                            {hasPermission(orderAdminNameShowPermission) ? elem?.user?.id : elem?.user?.isAdmin ? "Manager" : elem?.user?.name}
                                        </Box>
                                        {(hasPermission(orderEditPermission) || hasPermission(dealershipExcludeSelectPermission)) &&
                                            <Flex ml={"auto"}>
                                                <IconWithTooltip icon={FiTag} tooltipMessage={'All Items Purchased'}
                                                                 onClick={() => navigate(`${URLPaths.ALL_PRODUCTS_ORDER.link}${elem?.dealership?.id}`)}
                                                />
                                                <IconWithTooltip icon={FiBriefcase}
                                                                 tooltipMessage={'All orders this dealership'}
                                                                 onClick={() => {
                                                                     setSelectedItems([]);
                                                                     navigate(`${URLPaths.ORDER_HISTORY.link}/${elem?.dealership?.id}`)
                                                                 }}
                                                />
                                            </Flex>
                                        }
                                        <Flex ml={"auto"}>
                                            {hasPermission(orderEditPermission) && elem.paymentStatus === OrderTypes.IN &&
                                                <Box onClick={() => handlePrintPressed(DocumentTypes.Invoice, elem)}>
                                                    <IconWithTooltip icon={FiPrinter}
                                                                     tooltipMessage={`Printed(${elem.printPressed})`}
                                                                     isOutlined={elem.paymentStatus === OrderTypes.IN && elem.printPressed === 0}/>
                                                </Box>
                                            }
                                            {hasPermission(orderEditPermission) &&
                                                <Box onClick={() => {
                                                    handleSendInvoice(elem)
                                                }}>
                                                    <IconWithTooltip icon={FiSend}
                                                                     tooltipMessage={`Send(${elem.sendCount})`}
                                                                     isOutlined={elem.paymentStatus === OrderTypes.IN && elem.sendCount === 0}/>
                                                </Box>
                                            }
                                            {(elem.paymentStatus !== OrderTypes.IN || hasPermission(orderViewPartnerPermission)) &&
                                                <Box onClick={() => downloadInvoice(elem)}>
                                                    <IconWithTooltip icon={FiDownload} tooltipMessage={'Download'}/>
                                                </Box>}
                                            {hasPermission(orderEditPermission) &&
                                                <Box onClick={() => handleReorderInvoice(elem)}>
                                                    <IconWithTooltip icon={FiCopy} tooltipMessage={'Reorder'}/>
                                                </Box>
                                            }
                                            {hasPermission(orderRemovePermission) &&
                                                <Box onClick={() => confirmRemove(elem?.id)}>
                                                    <IconWithTooltip icon={FiTrash} tooltipMessage={'Remove'}/>
                                                </Box>
                                            }
                                        </Flex>
                                    </Box>
                                </Flex>,
                            isLink: false,
                            width:
                                "100%",
                            justifyContent:
                                "flex-start"
                        }] :
                    [
                        {
                            content: <Flex>
                                <Checkbox font={"14px"} px={2}
                                          icon={isExcluded === '2' ? <AiOutlineClose/> : undefined}
                                          colorScheme={isExcluded === '2' ? 'red' : undefined}
                                          isChecked={selectedItems.some(item => item.id === elem.id)}
                                          onChange={() => {
                                              handleOnclickNew(elem, selectedItems.some(item => item.id === elem.id))
                                          }}
                                />
                                <Text> {hasPermission(orderEditPermission) ? format(parseDateString(elem?.createdAt), 'MM.dd.yyyy') : elem?.createdAt}</Text>
                            </Flex>,
                            isLink: false,
                            width: selectedColumns.find((col) => col.header === 'DATE')?.width || "auto",
                        },
                        {
                            content: noShowPrepareIdOrder ? <Text textDecoration={"underline"} cursor={"pointer"}
                                                                  _hover={{color: "gray.800", textDecoration: "unset"}}
                                                                  onClick={() => navigate(`${URLPaths.ORDER_VIEW.link}${elem?.id}`)}>{'order received'}</Text> :
                                <ShowOrderModal order={elem}/>,
                            width: selectedColumns.find((col) => col.header === 'ORDER')?.width || "auto",
                        },
                        {
                            content: hasPermission(orderAdminNameShowPermission) ? elem?.user?.name : elem?.user?.isAdmin ? "Manager" : elem?.user?.name,
                            width: selectedColumns.find((col) => col.header === 'USER')?.width || "auto",
                        },
                        hasPermission(orderDealershipShowPermission) && {
                            content: <Flex>
                                <IconWithTooltip icon={FiTag} tooltipMessage={'All Items Purchased'}
                                                 onClick={() => navigate(`${URLPaths.ALL_PRODUCTS_ORDER.link}${elem?.dealership?.id}`)}
                                />
                                <IconWithTooltip icon={FiBriefcase} tooltipMessage={'All orders this dealership'}
                                                 onClick={() => {
                                                     setSelectedItems([]);
                                                     navigate(`${URLPaths.ORDER_HISTORY.link}/${elem?.dealership?.id}`)
                                                 }}
                                />

                                {hasPermission(dealershipExcludeSelectPermission) ?
                                    <Text
                                        pl={2}>{elem?.dealership?.name}{elem?.dealership?.localStatus ? ' ( L )' : ''}</Text> :
                                    <Text pl={2} _hover={{color: "gray.800", textDecoration: "underline"}}
                                          cursor={'pointer'}
                                          onClick={() => setIdState({
                                              companyId: elem?.dealership?.companyId,
                                              dealershipId: elem?.dealership?.id
                                          })}>
                                        {elem?.dealership?.name}{elem?.dealership?.localStatus ? ' ( L )' : ''}</Text>
                                }
                            </Flex>,
                            width: selectedColumns.find((col) => col.header === 'DEALERSHIP')?.width || "auto",
                        },
                        hasPermission(orderStatusShowPermission) && {
                            content: <Flex flexDir={"row"}>
                                <Text>
                                    {getOrderState(elem.paymentStatus, !hasPermission(orderEditPermission)).name}
                                </Text>
                                {(hasPermission(orderEditPermission) && (elem?.paymentStatus === OrderTypes.PENDING ||
                                        (elem?.paymentStatus === OrderTypes.IN && ((elem.sendCount > 0 && elem.printPressed > 0) || hasPermission(orderRemovePermission))))
                                ) && (
                                    <IconWithTooltip icon={AngleDoubleRight}
                                                     tooltipMessage={'Next stage ' + (elem.paymentStatus === OrderTypes.IN ? getOrderState(OrderTypes.PENDING).name : getOrderState(OrderTypes.PAID).name)}
                                                     onClick={() => confirmPayment(elem, elem?.paymentStatus)}/>
                                )}
                            </Flex>,
                            isLink: false,
                            width: selectedColumns.find((col) => col.header === 'STATUS')?.width || "auto",
                        },
                        hasPermission(orderAmountShowPermission) && {
                            content: elem?.totalAmount,
                            isLink: false,
                            width: selectedColumns.find((col) => col.header === 'AMOUNT')?.width || "auto",
                        },
                        hasPermission(orderEditPermission) && elem.paymentStatus === OrderTypes.IN && {
                            content: <IconWithTooltip icon={FiPrinter} tooltipMessage={`Printed(${elem.printPressed})`}
                                                      isOutlined={elem.paymentStatus === OrderTypes.IN && elem.printPressed === 0}/>,
                            clickAction: () => {
                                handlePrintPressed(DocumentTypes.Invoice, elem);
                            },
                            isLink: true,
                            width: "auto",
                        },
                        (elem.paymentStatus !== OrderTypes.IN || hasPermission(orderViewPartnerPermission)) && {
                            content: <IconWithTooltip icon={FiEye} tooltipMessage={'Show'}/>,
                            clickAction: () => showInvoice(elem),
                            isLink: true,
                            width: "auto",
                        },
                        hasPermission(orderEditPermission) && {
                            content: <IconWithTooltip icon={FiSend} tooltipMessage={`Send(${elem.sendCount})`}
                                                      isOutlined={elem.paymentStatus === OrderTypes.IN && elem.sendCount === 0}/>,
                            clickAction: () => handleSendInvoice(elem),
                            isLink: true,
                            width: "auto",
                        },
                        (elem.paymentStatus !== OrderTypes.IN || hasPermission(orderViewPartnerPermission)) && {
                            content: <IconWithTooltip icon={FiDownload} tooltipMessage={'Download'}/>,
                            clickAction: () => downloadInvoice(elem),
                            isLink: true,
                            width: "auto",
                        },
                        hasPermission(orderEditPermission) && {
                            content: <IconWithTooltip label={'PS'} tooltipMessage={'Packing Slip'}/>,
                            clickAction: () => handlePrintPressed(DocumentTypes.PackingSleep, elem),
                            isLink: true,
                            width: "auto",
                        },
                        hasPermission(orderEditPermission) && {
                            content: <IconWithTooltip icon={FiCopy} tooltipMessage={'Reorder'}/>,
                            clickAction: () => handleReorderInvoice(elem),
                            isLink: true,
                            width: "auto",
                        },
                        hasPermission(orderRemovePermission) && {
                            content: <IconWithTooltip icon={FiTrash} tooltipMessage={'Remove'}/>,
                            clickAction: () => confirmRemove(elem?.id),
                            isLink: true,
                            width: "auto",
                        },
                    ].filter(Boolean) as Array<ListProductItemConfig>;
                return <ListProductItemRow key={idx} list={ItemConfig}
                                           style={{
                                               fontSize: "13px",
                                               minHeight: "35px",
                                               justifyContent: "flex-start",
                                               bgColor: hasPermission(orderEditPermission) ? getOrderState(elem?.paymentStatus).color : 'unset'
                                           }}/>;
            })
            : null;


        return (
            <>
                {!account &&
                    <Breadcrumbs/>
                }
                <Flex flexDir="column" height="100%">
                    {/*<Flex flexDir="row" mb="10px" justifyContent="space-between" alignItems="end">*/}
                    {filterInterceptor.getFilterMap().size > 0 &&
                        <Flex pb={3}
                              alignItems="end"
                              width={isMobile ? "100%" : "80%"}
                              fontFamily="Poppins"
                              position="relative"
                              flexWrap="wrap">
                            {filterInterceptor.getFiltersRowWithCreateButton()}
                        </Flex>
                    }
                    {/*{id &&*/}
                    <HStack justifyContent="space-between">
                        <Flex>
                            <RadioGroup onChange={setIsExcluded} value={isExcluded}>
                                <Stack direction='row'>
                                    <Radio value='1' size='md'>Include</Radio>
                                    <Radio value='2' size='md' colorScheme='red'>Exclude</Radio>
                                </Stack>
                            </RadioGroup>
                            <Tag ml={1}
                                 size={'sm'}
                                 key={'sm'}
                                 variant='solid'
                                 colorScheme={(isExcluded === '2') ? 'red' : 'blue'}
                            >
                                <TagLabel>{`selected ${countSelected}`}</TagLabel>
                                <TagCloseButton onClick={() => setSelectedItems([])}/>
                            </Tag>
                        </Flex>

                        <Flex pt={1} width={isMobile ? "14%" : "10%"} justifyContent={"flex-end"}>
                            {((selectedItems && selectedItems.length > 1) || (isExcluded === '2')) &&
                                <>
                                    <IconWithTooltip icon={FiDownload} tooltipMessage={'Download'} fontSize={"22px"}
                                                     isLoading={isDownloading}
                                                     onClick={() => downloadInvoice()}/>
                                    {!isMobile &&
                                        <IconWithTooltip icon={FiEye} tooltipMessage={'Show'} fontSize={"22px"}
                                                         onClick={() => showInvoice()}/>
                                    }
                                    <AllowedAccess permissionKey={orderEditPermission}>
                                        <IconWithTooltip icon={AngleDoubleRight}
                                                         tooltipMessage={'Сhange to paid'}
                                                         isLoading={isConfirmPayment}
                                                         fontSize={"22px"}
                                                         onClick={() => confirmPayment(undefined, OrderTypes.PENDING)}/>
                                        {id &&
                                            <>
                                                <IconWithTooltip icon={FiPrinter} tooltipMessage={`Print select`}
                                                                 fontSize={"22px"}
                                                                 onClick={() => handlePrintPressed(DocumentTypes.Invoice)}/>
                                                <IconWithTooltip icon={FiSend} tooltipMessage={'Send select'}
                                                                 isLoading={isSendInvoice}
                                                                 fontSize={"22px"}
                                                                 onClick={() => handleSendInvoice()}/>
                                            </>
                                        }
                                    </AllowedAccess>
                                </>
                            }
                            <AllowedAccess permissionKey={orderEditPermission}>
                                {!id &&
                                    <LoadStatementVendorPopover item={new Object()} clickAction={downloadFunc}/>
                                }
                                <Box>
                                    <DownloadButton clickAction={() => downloadFunc(false)}
                                                    label={`Upload Excel count: ${id ? countSelected : orderClientList?.count}`}
                                                    nameFile={"statement.xlsx"} icon={AiOutlineFileExcel}/>
                                </Box>
                            </AllowedAccess>
                        </Flex>
                    </HStack>
                    {/*}*/}
                    <Flex justifyContent={"flex-start"}>
                        {((selectedItems && selectedItems.length > 1) || (isExcluded === '2')) && (
                            <>
                                <Flex pt={1} flexWrap="wrap" maxHeight={"200px"} overflowY={"auto"}
                                      width={isMobile ? "100%" : "90%"}>
                                    {selectedItems.map((item) => (
                                        <Box px={"2px"} pb={"1px"} key={item.id}>
                                            <Tag
                                                size={'sm'}
                                                key={'sm'}
                                                variant='solid'
                                                colorScheme={(isExcluded === '2') ? 'red' : 'blue'}
                                            >
                                                <TagLabel>{item.id}</TagLabel>
                                                <TagCloseButton onClick={() => handleOnclickNew(item, true)}/>
                                            </Tag>
                                        </Box>
                                    ))}
                                </Flex>
                            </>
                        )}
                    </Flex>
                    {/*</Flex>*/}
                    {isLoading ? <Loader/> :
                        (isPdf === 0 ?
                                <Box>
                                    {idState ?
                                        <Box>
                                            <CloseButton ml={"auto"} boxSize={"8"}
                                                         onClick={() => setIdState(null)}/>
                                            <ClientForm links={[URLPaths.HOME, URLPaths.COMPANIES]}
                                                        isEditing={false}
                                                        isParent={true} companyId={idState.companyId}
                                                        dealershipId={idState.dealershipId}></ClientForm>
                                        </Box> :
                                        <>
                                            <ListProductItemRow list={ColumnNamesMapping} style={{
                                                fontSize: "14px",
                                                fontWeight: "700",
                                                backgroundColor: "aliceblue",
                                                justifyContent: "flex-start"
                                            }}/>
                                            {ListAll}
                                        </>
                                    }
                                </Box> :
                                <Box>
                                    <CloseButton ml={"auto"} boxSize={"8"} onClick={() => {
                                        setPdf(0)
                                        setGroupOrder(null)
                                        setSelectedItems([])
                                    }}/>
                                    <PDFViewer reqFilter={buildFilters(groupOrder)} orderIds={selectedItems}
                                               isPrint={isPrint}
                                               isDocumentTypes={isDocumentType}/>
                                </Box>
                        )
                    }
                </Flex>
                {orderClientList?.count > 20 &&
                    <Flex justifyContent={{base: "center", lg: "flex-start"}}>
                        <Paginator step={20}
                                   limit={typeof orderClientList?.count === 'number' ? orderClientList?.count : 0}
                                   current={currentPaginatorPosition}
                                   swapPageTrigger={handlePageChange}/>
                    </Flex>
                }
            </>
        );
    }
;

export default OrderHistory;